import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const Verification = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract otp from the location state or use a default value
  const initialOtp = location.state?.otp || '';
  const nif = location.state?.nif || '';

  const maskedPart = location.state?.maskedPart || '';

  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the OTP
    if (otp.join('') === initialOtp) {
      // Move forward or navigate to the next page (replace '/next-page' with your actual route)
      // history.push('/next-page');
      localStorage.setItem('nif', nif);
      navigate('/property/'+nif);
    } else {
      // Display an error message
      setError('Invalid OTP. Please try again.');
    }
  };

  const handleInputChange = (index, value) => {
    // Update the OTP state
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input box if a number is entered
    if (value.match(/^\d$/) && index < 5) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex align-items-center">
        {/* Picture Container (2/3 left) */}
        <div className="col-md-7 picture-container">
          <div className="overlay"></div>
          {/* You can add any additional content here if needed */}
        </div>

        {/* Signup Form Container (1/3 right) */}
        <div className="col-md-4 signup-form-container mx-auto text-center">
          {/* Your signup form goes here */}
          <form onSubmit={handleSubmit}>
            <h2 className="text-center"><b>Verification</b></h2>
            <p className="text-center">Enter the 6-digit OTP code sent to<br/>{maskedPart}</p><br/>

            <div className="form-group">
              <div className="form-group otp-input">
                {/* Create 6 input boxes for OTP */}
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    className="form-control"
                    maxLength="1"
                    ref={inputRefs.current[index]}
                    value={value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    required
                  />
                ))}
                
              </div>
            </div>
            {error && <p className="text-danger text-center">{error}</p>}

            <br/>

            <button type="submit" className="btn btn-primary btn-block text-center">Access</button>
            <br/>
            <a href="#" className="text-center"><b>Resend code</b></a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Verification;

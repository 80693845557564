import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SignIn = () => {
  const navigate = useNavigate();
  const [idNumber, setIdNumber] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let url=process.env.REACT_APP_BACKEND_URL
      // Send the entered ID number to the backend
      const response = await axios.post(url+'login', {
        idNumber,
      });

      // Assuming the backend responds with the OTP in the response data
      const { otp } = response.data;
      const { email } = response.data;
      const { nif } = response.data;
      const atIndex = email.indexOf('@');
      let maskedPart = email.substring(0, Math.min(atIndex, 5)) + '*'.repeat(Math.max(atIndex - 5, 0));
      maskedPart=maskedPart + email.substring(atIndex);

      // Navigate to the next page with the received OTP
      navigate('/verification', { state: { otp,maskedPart,nif } });
    } catch (error) {
      console.log(error)
      // Handle errors, e.g., network issues or invalid response
      setError('Failed to verify ID. Please try again.');
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex align-items-center">
          {/* Picture Container (2/3 left) */}
          <div className="col-md-7 col-sm-12 picture-container">
            <div className="overlay"></div>
            {/* You can add any additional content here if needed */}
          </div>

          {/* Signup Form Container (1/3 right) */}
          <div className="col-md-4 signup-form-container mx-auto">
            {/* Your signup form goes here */}
            <form onSubmit={handleSubmit}>
              <h2 className="text-center"><b>Sign In</b></h2>
              <p className="text-center">An OTP Verification code will be sent to the<br/> email address associated
                with your <br/> National ID Number.</p><br/>

              <div className="form-group">
                <label htmlFor="username"><b>National ID Number</b></label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Enter your id number"
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                />
              </div>
              {error && <p className="text-danger text-center">{error}</p>}

              <br/>
              <button type="submit" className="btn btn-primary btn-block text-center">Continue</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';





const Property = () => {
  const { id } = useParams();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        let url=process.env.REACT_APP_BACKEND_URL
        const response = await axios.post(url+'property', {
          id,
        }); 
        setProperties(response.data.property);
      } catch (error) {
        console.error('Error fetching properties:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [id,properties]);
  const handlePropertyClick = (propertyName) => {
    // Save the property name to localStorage
    localStorage.setItem('selectedProperty', propertyName);

    // Move to the next page (replace '/finish' with your actual route)
    // You can use the Link component for internal navigation
    // <Link to="/finish">...</Link>
    navigate('/finish/'+id);
  };


  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark mobile-navbar">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <br></br>
                  <div className="progress-step">
                    <div className="inner-circle"></div>
                    <a href={`/property/${id}`} className='linkk'>
                    <span className="steps">Property</span>
                    </a>  
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step disable-outer">
                    <div className="inner-circle disable"></div>
                    <span className="steps disable-text">Finishing</span>
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step disable-outer">
                    <div className="inner-circle disable"></div>
                    <span className="steps disable-text">Signature</span>
                  </div>
                  {/* Add more steps as needed */}
        </div>
      </nav>
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <nav className="col-md-2 d-none d-md-block sidebar">
          <div className="sidebar-sticky">
            <h2 className="logo">Logo</h2>
            <div className="h">
              <div className="row">
                <div className="progress-container">
                  <div className="progress-step">
                    <div className="inner-circle"></div>
                    <a href={`/property/${id}`} className='linkk'>
                    <span className="steps">Property</span>
                    </a>                  
                    </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step disable-outer">
                    <div className="inner-circle disable"></div>
                    <span className="steps disable-text">Finishing</span>
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step disable-outer">
                    <div className="inner-circle disable"></div>
                    <span className="steps disable-text">Signature</span>
                  </div>
                  {/* Add more steps as needed */}
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div>
      {/* Burger Icon */}
      

      {/* Mobile Navbar */}
    </div>

        {/* Content */}
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4 text-center">
        <br/><br/>
        <h2 className="text-center">Properties</h2>
        <p className="text-center">Select a Property to choose finishing options for your space</p>
        {loading ? (
          <p>Loading properties...</p>
        ) : (
          <div className="row">
            {properties.map(property => (
              <div className="col-md-6">
                {/* Make the image and property text clickable */}
                <button
                  className="default-link"
                  onClick={() => handlePropertyClick(property)}
                >
                  <img className="property-image" src='/download.png' alt='property' />
                  <br/><br/>
                  <p><b>{property}</b></p>
                </button>
              </div>
            ))}
          </div>
        )}
      </main>
        </div>
    </div>
    </div>

  );

};

export default Property;

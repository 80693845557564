import './App.css';
import Singin from './signin/signin';
import { Route, Routes } from 'react-router-dom';
import Verification from './signin/verification';
import Property from './client/property';
import Finish from './client/finish';
import Signature from './client/signature';
import Confirmation from './client/confirmation';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Singin/> } />
        <Route path="/verification" element={ <Verification/> } />
        <Route path="/property/:id" element={ <Property/> } />
        <Route path="/finish/:id" element={ <Finish/> } />
        <Route path="/signature/:id" element={ <Signature/> } />
        <Route path="/confirmation/:id" element={ <Confirmation/> } />





      </Routes>
    </div>
  );
}

export default App;

import React, {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Signature = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedImageDescription, setSelectedImageDescription] = useState('');

  const { id } = useParams();
  const [propertyName, setPropertyName] = useState('');

    const navigate=useNavigate()
    const handleNextPage = () => {
        navigate('/confirmation/'+id);
    
      };
      useEffect(() => {
        // Retrieve data from localStorage
        const storedData = localStorage.getItem('selectedData');
        const storedPropertyName = localStorage.getItem('selectedProperty');
        if (storedPropertyName) {
          setPropertyName(storedPropertyName);
        }
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setSelectedOptions(parsedData.selectedOptions || []);
          setSelectedImageDescription(parsedData.selectedImageDescription || '');
        }
      }, []);
    
    
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mobile-navbar">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <br></br>
                  <div className="progress-step active-outer">
                    <div className="inner-circle active"></div>
                    <a href={`/property/${id}`} className='linkk'>
                    <span className="steps">Property</span>
                    </a>  
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step active-outer">
                    <div className="inner-circle active"></div>
                    <a href={`/finish/${id}`} className='linkk'>
                    <span className="steps">Finishing</span>
                    </a>  
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step">
                    <div className="inner-circle"></div>
                    <a href={`/signature/${id}`} className='linkk'>
                    <span className="steps">Signature</span>
                    </a>  

                  </div>
                  {/* Add more steps as needed */}
        </div>
      </nav>
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <nav className="col-md-2 d-none d-md-block sidebar">
          <div className="sidebar-sticky">
            <h2 className="logo">Logo</h2>
            <div className="h">
              <div className="row">
                <div className="progress-container">
                  <div className="progress-step active-outer">
                    <div className="inner-circle active"></div>
                    <a href={`/property/${id}`} className='linkk'>
                    <span className="steps">Property</span>
                    </a>
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step active-outer">
                    <div className="inner-circle active"></div>
                    <a href={`/finish/${id}`} className='linkk'>
                    <span className="steps">Finishing</span>
                    </a>
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step">
                    <div className="inner-circle"></div>
                    <a href={`/signature/${id}`} className='linkk'>
                    <span className="steps">Signature</span>
                    </a>
                  </div>
                  {/* Add more steps as needed */}
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* Content */}
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
          <br />
          <br />
          <h2 className="text-center">Signature</h2>
          <p className="text-center">Input your Signature to seal your choices</p>
          <br />
          <div className="row justify-content-md-center">
            <div className="col-md-6">
              <div className="options">
                <h3 className="logo text-center">LOGO</h3>

                <p><b>{propertyName}</b></p>
                <p><b>Location:</b></p>
                <p>264 Royal Ln. Mesa, New Jersey</p>
                <br />
                <br />
                <p><b>Selected Choice:</b></p>
            <p>{selectedImageDescription}</p>
            {selectedOptions.map((option, index) => (
              <div key={index}>
                <p>{option.category}</p>
                <ul>
                  <li>{option.description}</li>
                </ul>
              </div>
            ))}

              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row mt-3">
          <div className="col text-right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleNextPage}
            >
              Signature
            </button>
          </div>
        </div>
          <br />
          <br />
        </main>
      </div>
    </div>
    </div>

  );
};

export default Signature;

import React from 'react';
const Confirmation = () => {
  return (
    <div className="image-conf">
          {/* Image container */}
          <div className="image-container">
            {/* Overlay */}
            <div className="overlay-finish">
              {/* Text */}
              <div className="overlay-text">
                <h1>Thank You</h1>
              <p>We ensure to deliver the best</p>
              </div>

        </div>
      </div>
    </div>
  );
};

export default Confirmation;

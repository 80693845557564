import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Finish = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const optionsDetails = {
    Bathroom: [
      { image: "/image 5.png", description: "MARGRES PURE STONE LIGHT GREY" },
      { image: "/image 6.png", description: "MARGRES PURE STONE LIGHT GREY" },
    ],
    Kitchen: [
      { image: "/image 5.png", description: "Another Kitchen Option" },
      { image: "/image 6.png", description: "Yet Another Kitchen Option" },
    ],
  };

  const handleNextPageClick = () => {
    const dataToSave = {
      selectedOptions: dynamicHeadings,
      selectedImageDescription: selectedImageDescription,
    };
    localStorage.setItem('selectedData', JSON.stringify(dataToSave));

    navigate('/signature/'+id);
  };

  const [selectedOption, setSelectedOption] = useState('Bathroom');
  const [dynamicHeadings, setDynamicHeadings] = useState([]);
  const [selectedImageDescription, setSelectedImageDescription] = useState('');

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setSelectedImageDescription('');
  };

  const handleImageClick = (description) => {
    setDynamicHeadings((prevHeadings) => {
      const existingIndex = prevHeadings.findIndex(
        (heading) => heading.category === selectedOption
      );

      if (existingIndex !== -1) {
        // Update existing description
        const updatedHeadings = [...prevHeadings];
        updatedHeadings[existingIndex].description = description;
        return updatedHeadings;
      } else {
        // Add a new entry
        return [
          ...prevHeadings,
          { category: selectedOption, description },
        ];
      }
    });
  };
  const [propertyName, setPropertyName] = useState('');

  // Fetch property_name from localStorage when the component mounts
  useEffect(() => {
    // Retrieve property_name from localStorage
    const storedPropertyName = localStorage.getItem('selectedProperty');

    // Update state if property_name is found
    if (storedPropertyName) {
      setPropertyName(storedPropertyName);
    }
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  return (
    <div>
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark mobile-navbar">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <br></br>
                  <div className="progress-step active-outer">
                    <div className="inner-circle active"></div>
                    <a href={`/property/${id}`} className='linkk'>
                    <span className="steps">Property</span>
                    </a>  
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step">
                    <div className="inner-circle"></div>
                    <a href={`/finish/${id}`} className='linkk'>
                    <span className="steps">Finishing</span>
                    </a>  
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step disable-outer">
                    <div className="inner-circle disable"></div>
                    <span className="steps disable-text">Signature</span>
                  </div>
                  {/* Add more steps as needed */}
        </div>
      </nav>
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <nav className="col-md-2 d-none d-md-block sidebar">
          <div className="sidebar-sticky">
            <h2 className="logo">Logo</h2>
            <div className="h">
              <div className="row">
                <div className="progress-container">
                  <div className="progress-step active-outer">
                    <div className="inner-circle active"></div>
                    <a href={`/property/${id}`} className='linkk'>
                    <span className="steps">Property</span>
                    </a>
                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step">
                    <div className="inner-circle"></div>
                    <a href={`/finish/${id}`} className='linkk'>
                    <span className="steps">Finishing</span>
                    </a>  

                  </div>
                  <div className="progress-bar"></div>
                  <div className="progress-step disable-outer">
                    <div className="inner-circle disable"></div>
                    <span className="steps disable-text">Signature</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* Content */}
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
          <br/><br/>
          <h2 className="text-center">Properties</h2>
          <p className="text-center">Select a Property to choose finishing options for your space</p>
          <br/>
          <div className="row">
            <div className="md-col-4">
              <img className="property-image-finish" src="/download.png" alt="Property" />
            </div>
            <div className="col">
              <p><b>{propertyName}</b></p>
              <p><b>Description:</b></p>
              <p>264 Royal Ln. Mesa, New Jersey</p>
              <br/><br/>
              {dynamicHeadings.map((heading, index) => (
                <div key={index}>
                  <p><b>{heading.category}</b></p>
                  <p>{heading.description}</p>
                </div>
              ))}
            </div>
            <div className="col-md-4 mx-auto justify-content-md-center selectt" id=''>
              <div className="form-group">
                <label htmlFor="sel1">Select list:</label>
                <select
                  className="form-control"
                  id="sel1"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="Bathroom">Bathroom</option>
                  <option value="Kitchen">Kitchen</option>
                </select>
              </div>
              <div className="options">
                <br/>
                <div className="row justify-content-md-center text-center">
                  {optionsDetails[selectedOption].map((option, index) => (
                    <div className="col-5 choice" key={index}>
                      <img
                        src={option.image}
                        alt={`Option ${index + 1}`}
                        onClick={() => handleImageClick(option.description)}
                      />
                      <p><small>{option.description}</small></p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col text-left">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleNextPageClick}
              >
                Signature
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
    </div>
  );
};

export default Finish;
